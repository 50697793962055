import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

// Assets 
import footerLogo from '../images/logo-footer.svg'

const Footer = () => {

  let imgattr = "Footer logo"

  return (
    <StaticQuery
      query={graphql`
        query {
          wpMenu(slug: {eq: "menu-proposte"}) {
            menuItems {
              nodes {
                label
                url
              }
            }
          }
        }
      `}
      render={data => (
        <footer className="footer-area">
          <div className="container">
            <div className="footer-top">
              <div className="row">
                <div className="col-sm-4 col-6">
                  <a className="footer-logo" href="/"><img src={ footerLogo } alt={ imgattr } /></a>
                </div>
                <div className="col-sm-8 col-6">
                  <div className="footer-social text-sm-right">
                    <span>SEGUICI</span>
                    <ul className="social-icon">
                      <li>
                        <a href={ 'https://www.facebook.com/SognoCasaLiguria' } target="_blank" rel="noreferrer" aria-label="icon"><i className="fa fa-facebook" /></a>
                      </li>
                    </ul>
                  </div>  
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row">
                <div className="col-md-4 col-sm-12 d-flex justify-content-center">
                  <div className="widget widget_nav_menu">
                    <p>L’agenzia <b>Sogno Casa Liguria</b>, con sede ad Alassio, è specializzata nella vendita di immobili di prestigio nella Riviera di Ponente.</p>
                  </div>
                </div>
                <div className="col-md-4 col-6 col-min">
                  <div className="widget widget_nav_menu">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/chi-siamo">Chi siamo</Link>
                      </li>
                      {/* <li>
                        <Link to="/news">News</Link>
                      </li> */}
                      <li>
                        <Link to="/contatti">Contatti</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-6 col-min">
                  <div className="widget widget_nav_menu">
                  <h4 className="widget-title">I luoghi in evidenza</h4>
                  <ul>
                    {data.wpMenu.menuItems.nodes.map(node => (
                      <li
                        key={node.url}
                      >
                        <Link to={`${'../' + node.url}`.substring(12)}>{node.label}</Link>
                      </li>
                    ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-right text-center">LORENZO srl © 2020 - P.IVA 11618650011 - REA TO1227897 - CAP. SOC. €10.000 - <Link to="/privacy-policy" target="_blank" rel="noreferrer" className="external-link">Privacy policy</Link> - Made with <i className="fa fa-heart"></i> by <a href="https://adokstudio.it/" target="_blank" rel="noreferrer" className="external-link">ADOK studio</a></div>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer