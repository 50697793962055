import React from "react"
import Navbar from "./navbar"
import { Link } from "gatsby"

import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/vendor.css"
import Footer from "./footer"

import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />

      <CookieConsent
          location="bottom"
          buttonText="Accetto"
          declineButtonText="Rifiuto"
          cookieName="gatsby-gdpr-google-analytics">
      Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito. Se continui ad utilizzare questo sito noi assumiamo che tu ne sia felice. <Link to="/privacy-policy" target="_blank" rel="noreferrer" className="external-link">Privacy policy</Link>
      </CookieConsent>

    </>
  )
}

export default Layout
