import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

// Assets 
import logo from '../images/logo.svg'

export default function Navbar() {

  return (
    <StaticQuery
      query={graphql`
        query {
          wpMenu(slug: {eq: "menu-proposte"}) {
            menuItems {
              nodes {
                label
                url
              }
            }
          }
        }
      `}
      render={data => (
        <header className="navbar-area">
          <nav className="navbar navbar-area navbar-expand-lg">
            <div className="container nav-container">
              <div className="responsive-mobile-menu">
                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="icon-left" />
                  <span className="icon-right" />
                </button>
              </div>
              <div className="logo readeal-top">
                <Link to="/"><img src={logo} alt="logo" /></Link>
              </div>
              <div className="nav-right-part nav-right-part-mobile">
                <a className="btn btn-yellow" href="tel:+393391745031">+39 339 1745031</a>
              </div>
              <div className="collapse navbar-collapse" id="realdeal_main_menu">
                <ul className="navbar-nav menu-open readeal-top">
                  <li className="current-menu-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="/">Proposte</a>
                    <ul className="sub-menu">
                      <li
                        key="tutte-le-localita"
                      >
                        <Link to="/tutte-le-localita/">Tutte le località</Link>
                      </li>
                      {data.wpMenu.menuItems.nodes.map(node => (
                        <li
                          key={node.url}
                        >
                          <Link to={`${'../' + node.url}`.substring(12)}>{node.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <Link to="/chi-siamo">Chi siamo</Link>
                  </li>
                  {/* <li>
                    <Link to="/news">News</Link>
                  </li> */}
                  <li>
                    <Link to="/contatti">Contatti</Link>
                  </li>
                </ul>
              </div>
              <div className="nav-right-part nav-right-part-desktop readeal-top">
                <a className="btn btn-yellow" href="tel:+393391745031">+39 339 1745031</a>
              </div>
            </div>
          </nav>
        </header>
      )}
    />
  )
}